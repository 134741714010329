<template>
  <div
    class="modal fade"
    id="modalViewBienBan"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalViewBienBanLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-full-width"
      role="document"
    >
      <div class="modal-content p-3 modal-pdf">
        <div class="d-flex justify-content-end pb-3">
          <button
            type="button"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span
              aria-hidden="true"
              class="robo-18-500"
            >&times;</span>
          </button>
        </div>
        <iframe
          v-if="concluding && concluding.pdf"
          :src="concluding.pdf+'#toolbar=0&navpanes=0&scrollbar=0'"
          frameBorder="0"
          scrolling="auto"
          height="100%"
          width="100%"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewBienBan',
  props: ['mdt_disease'],
  data () {
    return {
      concluding: null
    }
  },
  watch: {
    mdt_disease (d) {
      if (d && d.concluding) {
        this.concluding = d.concluding
      } else {
        this.concluding = null
      }
    }
  }
}
</script>

<style scoped>
.modal-pdf {
  height: 100vh !important;
}
</style>